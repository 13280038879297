import useSWRImmutable from "swr/immutable";
import { BlomqvistObject, Store, Wine, WineDetailed } from "../types";
import { API_URL } from "../util/constants";
import { trimSearchString } from "../util/utils";

const extractWineSearchString = (wine: Wine) =>
  trimSearchString(
    `${wine.title}${wine.fields.producer?.value ?? ""}${
      wine.fields.year?.value ?? ""
    }`
  );

const fetcher = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, init).then((res) => res.json());

const wineFetcher = async (input: RequestInfo, init?: RequestInit) => {
  const response = await fetch(input, init);
  const wine = await response.json();
  wine.searchString = extractWineSearchString(wine);
  return wine;
};

const winesFetcher = async (input: RequestInfo, init?: RequestInit) => {
  const response = await fetch(input, init);
  const wines = await response.json();
  wines.forEach((wine: Wine) => {
    wine.searchString = extractWineSearchString(wine);
    wine.createdDate = wine.createdDate
      ? new Date(wine.createdDate)
      : undefined;
  });
  return wines;
};

export function useWine(id: string) {
  const { data, error } = useSWRImmutable<WineDetailed>(
    `${API_URL}/ratings/${id}`,
    wineFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useWines() {
  const { data, error } = useSWRImmutable<Wine[]>(
    `${API_URL}/ratings`,
    winesFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useBlomqvistObjects(query: string) {
  const { data, error, isValidating } = useSWRImmutable<
    Record<string, BlomqvistObject[]>
  >(query ? `${API_URL}/blomqvist?query=${query}` : null, fetcher);

  return {
    data,
    isLoading: isValidating,
    isError: error,
  };
}

export function useStores() {
  const { data, error } = useSWRImmutable<Store[]>(
    `${API_URL}/stores`,
    fetcher
  );

  return {
    data: data?.map(toStore),
    isLoading: !error && !data,
    isError: error,
  };
}

export function useStockholding(store: Store | undefined) {
  const { data, error } = useSWRImmutable<Wine[]>(
    store ? `${API_URL}/stockholding/${store.query}` : null,
    winesFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

const toStore = (apiStore): Store => ({
  id: apiStore.query,
  name: apiStore.name,
  count: apiStore.count,
  query: apiStore.query,
});
