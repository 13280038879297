import { useState, useEffect } from "react";
import { FlatList, Platform, Text } from "react-native";
import { Wine } from "../types";
import { WineCard } from "./WineCard";

interface Props {
  wines: Wine[];
  onEndReached: () => void;
}

export const WineList = ({ wines, onEndReached }: Props) => {
  useEffect(() => {
    if (Platform.OS === "web") {
      window.addEventListener("scroll", isScrolledToBottom);
    }

    // returned function will be called on component unmount
    return () => {
      if (Platform.OS === "web") {
        window.removeEventListener("scroll", isScrolledToBottom);
      }
    };
  }, []);

  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState<boolean>(true);

  const renderItem = ({ item }) => <WineCard wine={item} />;

  const isScrolledToBottom = () => {
    const offset = 5;
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const scrolledToBottom =
      scrollTop + window.innerHeight + offset >= scrollHeight;

    if (scrolledToBottom) {
      onEndReached();
    }
  };

  return (
    <FlatList<Wine>
      style={{ width: "100%" }}
      data={wines}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      onEndReached={() => {
        if (!onEndReachedCalledDuringMomentum) {
          onEndReached();
          setOnEndReachedCalledDuringMomentum(true);
        }
      }}
      onEndReachedThreshold={0.1}
      onMomentumScrollBegin={() => {
        setOnEndReachedCalledDuringMomentum(false);
      }}
      ListEmptyComponent={<Text style={{ marginTop: 20 }}>Ingen treff..</Text>}
    />
  );
};
