import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  useWindowDimensions,
} from "react-native";
import "whatwg-fetch";
import { SortBy, Wine, WineFilter } from "../types";
import { WineList } from "../components/WineList";
import { SearchField } from "../components/SearchField";
import { useStockholding, useWines, useStores } from "../swr";
import {
  getUniqueGrapes,
  getUniqueMeals,
  getUniqueTypes,
  getUniqueLocations,
  isSmallScreen,
  NameAndCount,
} from "../util/utils";
import { Picker } from "../components/Picker";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { filterWines, sortWines } from "../util/filter";
import { MAX_PRICE_FILTER_VALUE } from "../util/constants";
import { Filters } from "../components/Filters";
import { Accordion } from "../components/Accordion";

const defaultNumResults = 12;
const defaultWineFilter: WineFilter = {
  selectedStore: undefined,
  selectedGrapes: [],
  selectedLocations: [],
  selectedTypes: [],
  selectedMeals: [],
  priceRange: {
    min: 0,
    max: MAX_PRICE_FILTER_VALUE,
  },
  scoreRange: {
    min: 50,
    max: 100,
  },
};

export function Vinolentus() {
  const wines = useWines();
  const [filteredWines, setFilteredWines] = useState<Wine[]>();
  const [query, setQuery] = useState<string>("");
  const [numResults, setNumResults] = useState<number>(defaultNumResults);
  const [wineFilter, setWineFilter] = useState<WineFilter>(defaultWineFilter);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Published);
  const [grapes, setGrapes] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [locations, setLocation] = useState<NameAndCount[]>([]);
  const [meals, setMeals] = useState<string[]>([]);
  const [isReadyToRenderWines, setIsReadyToRenderWines] =
    useState<boolean>(false);

  const stockholding = useStockholding(wineFilter.selectedStore);
  const {
    data: stores,
    isError: isErrorStores,
    isLoading: isLoadingStores,
  } = useStores();

  const onSearch = (query: string) => {
    setNumResults(defaultNumResults);
    setIsReadyToRenderWines(false);
    setQuery(query);
  };

  useEffect(() => {
    setNumResults(defaultNumResults);
    if (wines.data) {
      filterAndSort();
    }
    setIsReadyToRenderWines(true);
  }, [wines.data, query, wineFilter, stockholding.data, sortBy]);

  const filterAndSort = () => {
    let result = stockholding.data ?? wines.data;
    result = filterWines(result, query, wineFilter);
    // Don't sort if using text search
    if (!query || query.length <= 1) {
      result = sortWines(result, sortBy);
    }
    setFilteredWines(result);
  };

  useEffect(() => {
    setGrapes(getUniqueGrapes(wines.data));
    setTypes(getUniqueTypes(wines.data));
    setLocation(getUniqueLocations(wines.data));
    setMeals(getUniqueMeals(wines.data));
  }, [wines.data]);

  const { width } = useWindowDimensions();

  const isLoadingStockholding =
    wineFilter.selectedStore && stockholding.isLoading;
  const isLoading =
    wines.isLoading ||
    isLoadingStockholding ||
    !isReadyToRenderWines ||
    isLoadingStores;

  return (
    <View style={styles.background}>
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          {!isSmallScreen(width) && (
            <Filters
              wineFilter={wineFilter}
              stores={stores}
              grapes={grapes}
              locations={locations}
              meals={meals}
              types={types}
              onChange={(filter: WineFilter) => {
                setIsReadyToRenderWines(false);
                setWineFilter(filter);
              }}
              style={styles.filterColumn}
            />
          )}
          <View
            style={[
              styles.mainColumns,
              { width: isSmallScreen(width) ? "100%" : "70%" },
            ]}
          >
            <StatusBar style="auto" />
            <SearchField
              query={query}
              onChange={onSearch}
              disabled={wines.isLoading || wines.isError}
              placeholder={
                wines.data
                  ? `Søk blant ${wines.data.length.toLocaleString("no")} viner`
                  : "Laster.."
              }
            />
            {isSmallScreen(width) && (
              <Accordion
                dropdownLabel="Vis filter"
                accordionBodyStyle={{ width: "100%" }}
                accordionStyle={{ justifyContent: "flex-start" }}
              >
                <Filters
                  wineFilter={wineFilter}
                  stores={stores}
                  grapes={grapes}
                  locations={locations}
                  meals={meals}
                  types={types}
                  onChange={(filter: WineFilter) => {
                    setIsReadyToRenderWines(false);
                    setWineFilter(filter);
                  }}
                  style={styles.filterAccordion}
                />
              </Accordion>
            )}
            {(isLoadingStockholding || isLoading) && (
              <View
                style={{
                  minHeight: 100,
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LoadingSpinner
                  label={
                    isLoadingStockholding
                      ? "Laster lagerstatus. Dette kan ta en stund."
                      : undefined
                  }
                />
              </View>
            )}
            {filteredWines && !isLoading && (
              <>
                <View style={styles.hitsAndSort}>
                  <Text style={styles.hits}>
                    Antall treff: {filteredWines.length.toLocaleString("no")}
                  </Text>
                  <View style={styles.sort}>
                    <Text>Sorter etter: </Text>
                    <Picker<SortBy>
                      id="sortBy"
                      placeholder="Sorter etter"
                      selectedItem={sortBy}
                      items={[SortBy.Published, SortBy.Rating]}
                      onChange={(sort) => {
                        setIsReadyToRenderWines(false);
                        setSortBy(sort);
                      }}
                    />
                  </View>
                </View>
                <WineList
                  wines={filteredWines.slice(0, numResults)}
                  onEndReached={() => {
                    setNumResults(
                      (prevNumResults) => prevNumResults + defaultNumResults
                    );
                  }}
                />
              </>
            )}
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4EFEA",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: 15,
    width: "100%",
    maxWidth: 700,
  },
  mainColumns: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  background: {
    alignItems: "center",
    flex: 1,
    backgroundColor: "#F4EFEA",
  },
  hitsAndSort: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
  },
  hits: {
    fontSize: 11,
    lineHeight: 12,
    textTransform: "uppercase",
  },
  sort: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  filterAccordion: {
    alignSelf: "stretch",
    backgroundColor: "#FFFFFF",
    marginTop: 10,
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    paddingBottom: 30,
  },
  filterColumn: {
    backgroundColor: "#FFFFFF",
    marginTop: 89,
    marginRight: 15,
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "30%",
    paddingBottom: 30,
  },
});
