export interface WineField {
  value: any | any[];
  type?: string;
}

export interface Wine {
  id: string;
  createdDate: Date;
  type: string;
  fields: Record<string, WineField>;
  title: string;
  searchString: string;
  dnUrl: string;
}

export interface WineDetailed extends Wine {
  guid: string;
  state: string;
  url: string;
  metadata: string;
  first_published_at: string;
  published_at: string;
  created_at: string;
  updated_at: string;
  canonical_url: string;
  publication: {
    name: string;
    title: string;
    url: string;
  };
}

export interface Store {
  id: string;
  name: string;
  count: number;
  query: string;
}

export enum SortBy {
  Nothing = "",
  Published = "Publiseringsdato",
  Rating = "Poeng",
}

export interface MinMaxRange {
  min: number;
  max: number;
}

export interface WineFilter {
  selectedStore: Store | undefined;
  selectedGrapes: string[];
  selectedLocations: string[];
  selectedTypes: string[];
  selectedMeals: string[];
  priceRange: MinMaxRange;
  scoreRange: MinMaxRange;
}

export interface BlomqvistObject {
  id: string;
  title: string;
  producer: string;
  wineName: string;
  fullName: string;
  note?: string;
  numberOfItems: number;
  minimumPrice: number;
  valuationPrice: number;
  salesPrice: number | null;
  description: string;
  endDate: string;
  vintage?: string;
  photos?: {
    thumb: string[];
    large: string[];
    verylarge: string[];
  };
}
