import { Text, StyleSheet } from "react-native";
import { useWine } from "../swr";
import { WineField } from "../types";
import { containsGrapeInfo } from "../util/utils";
import { LoadingSpinner } from "./LoadingSpinner";

interface Props {
  id: string;
  grapes: WineField;
}

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 10,
  },
  grapeList: {
    fontSize: 14,
    lineHeight: 20,
  },
});

export const WineDetails = ({ id, grapes }: Props) => {
  const { data, isLoading } = useWine(id);

  return (
    <>
      <Text style={styles.text}>
        {isLoading ? <LoadingSpinner /> : data.fields.taste_notes.value}
      </Text>
      {containsGrapeInfo(grapes) &&
        grapes.value.map((grape) => (
          <Text
            key={grape.name}
            style={styles.grapeList}
          >{`\u2022 ${grape.percentage}% ${grape.name}`}</Text>
        ))}
    </>
  );
};
