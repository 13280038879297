import { BlomqvistObject, Wine, WineField } from "../types";

export const isSmallScreen = (screenWidth: number) => {
  return screenWidth < 769;
};

export const trimSearchString = (searchString: string) =>
  searchString.replace(/[^a-zA-Z0-9]/g, "");

const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const capitalizeFirstLetter = (word) => {
  if (word) {
    return word
      ?.trim()
      ?.toLowerCase()
      ?.split(" ")
      .map(capitalized)
      .toString()
      .replace(",", " ");
  }
};

export const containsGrapeInfo = (grapes: WineField) =>
  !!grapes?.value?.length &&
  !!grapes?.value[0].name &&
  !!grapes?.value[0].percentage;

export const getUniqueGrapes = (wines: Wine[]): string[] => {
  if (!wines) return [];
  const grapes = wines
    .map((wine) => wine.fields.grape)
    .filter(containsGrapeInfo)
    .flatMap((wine) => wine.value.map((it) => it.name));
  return Array.from(new Set(grapes)).sort((a, b) => a.localeCompare(b));
};

export interface NameAndCount {
  name: string;
  count: number;
}

export const getUniqueLocations = (wines: Wine[]): NameAndCount[] => {
  if (!wines) return [];
  const locations = wines
    .map((wine) => capitalizeFirstLetter(wine.fields?.country?.value))
    .filter((country) => !!country && country.match(/^([^0-9]*)$/))
    .reduce<Record<string, NameAndCount>>((countryWithCount, country) => {
      countryWithCount[country] = {
        name: country,
        count: (countryWithCount[country]?.count ?? 0) + 1,
      };
      return countryWithCount;
    }, {});
  return Object.values(locations).sort((a, b) => a.name.localeCompare(b.name));
};

export const getUniqueTypes = (wines: Wine[]): string[] => {
  if (!wines) return [];
  const types = wines
    .map((wine) => wine.fields.type?.value)
    .filter((type) => !!type);
  return Array.from(new Set(types)).sort((a, b) => a.localeCompare(b));
};

export const getUniqueMeals = (wines: Wine[]): string[] => {
  if (!wines) return [];
  const allMeals = wines
    .map((wine) => wine.fields.meal?.value)
    .flatMap((meals) => meals)
    .filter((meal) => !!meal);
  return Array.from(new Set(allMeals)).sort((a, b) => a.localeCompare(b));
};

export const calculateSalesPricePerItem = (blomqvistObject: BlomqvistObject) =>
  blomqvistObject.salesPrice &&
  blomqvistObject.salesPrice / blomqvistObject.numberOfItems;
