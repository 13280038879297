import React from "react";
import { StyleSheet, TextInput } from "react-native";

interface Props {
  onChange: (query: string) => void;
  placeholder: string;
  query: string;
  disabled?: boolean;
}

const styles = StyleSheet.create({
  input: {
    flex: 1,
    borderBottomColor: "#610E3D",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    fontSize: 18,
    color: "#33111D",
    width: "100%",
    padding: 9,
  },
});

export const SearchField = ({
  onChange,
  placeholder,
  query,
  disabled,
}: Props) => {
  return (
    <TextInput
      editable={disabled !== true}
      style={styles.input}
      onChangeText={onChange}
      value={query}
      placeholder={placeholder}
    />
  );
};
