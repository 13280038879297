import React, { useEffect, useRef } from "react";
import { StyleSheet, View, Animated, Easing, Text } from "react-native";
import { EvilIcons } from "@expo/vector-icons";

const startRotationAnimation = (rotationDegree: Animated.Value): void => {
  Animated.loop(
    Animated.timing(rotationDegree, {
      toValue: 360,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: false,
    })
  ).start();
};

interface Props {
  label?: string;
}

export const LoadingSpinner = ({ label }: Props) => {
  const rotationDegree = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    startRotationAnimation(rotationDegree);
  }, []);

  return (
    <View style={styles.container} accessibilityRole="progressbar">
      <Animated.View
        style={[
          {
            transform: [
              {
                rotateZ: rotationDegree.interpolate({
                  inputRange: [0, 360],
                  outputRange: ["0deg", "360deg"],
                }),
              },
            ],
          },
        ]}
      >
        <EvilIcons name="spinner-3" size={height} color="black" />
      </Animated.View>
      {label && <Text style={styles.text}>{label}</Text>}
    </View>
  );
};

const height = 30;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  text: {
    paddingLeft: 10,
  },
});
