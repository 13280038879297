import { View, Text, StyleSheet, StyleProp, ViewStyle } from "react-native";
import RangeSlider from "react-native-range-slider-expo";
import { MinMaxRange } from "../types";
import { formatInteger } from "../util/formatter";

interface Props {
  scoreRange: MinMaxRange;
  setScoreRange: (scoreRange: MinMaxRange) => void;
  minValue: number;
  maxValue: number;
  wrapperStyle?: StyleProp<ViewStyle>;
}

export const ScoreRangeSlider = ({
  setScoreRange,
  scoreRange,
  minValue,
  maxValue,
  wrapperStyle,
}: Props) => {
  const onFromValueChange = (value: number) => {
    setScoreRange({ min: value, max: scoreRange.max });
  };

  const onToValueChange = (value: number) => {
    setScoreRange({ min: scoreRange.min, max: value });
  };

  return (
    <View style={wrapperStyle}>
      <RangeSlider
        min={minValue}
        max={maxValue}
        fromValueOnChange={onFromValueChange}
        toValueOnChange={onToValueChange}
        styleSize="small"
        knobSize={24}
        showValueLabels={true}
        showRangeLabels={false}
        fromKnobColor="#CA7571"
        toKnobColor="#CA7571"
        outOfRangeBarColor="#DED2C8"
        inRangeBarColor="#33111D"
      />
      <View style={styles.labelsWrapper}>
        <Text>{formatInteger(scoreRange.min)} poeng</Text>
        <Text>{formatInteger(scoreRange.max)} poeng</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  labelsWrapper: {
    paddingHorizontal: 25,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: -30,
  },
});
