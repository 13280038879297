import React, { useState } from "react";
import { SafeAreaView, StyleSheet, View, Button, FlatList } from "react-native";
import "whatwg-fetch";
import { SearchField } from "../components/SearchField";
import { useBlomqvistObjects } from "../swr";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Accordion } from "../components/Accordion";
import { BlomqvistCard } from "../components/BlomqvistCard";
import { BlomqvistPriceChart } from "../components/BlomqvistPriceChart";
import { BlomqvistObject } from "../types";

export function Blomqvist() {
  const [query, setQuery] = useState<string>("");
  const [submittedQuery, setSubmittedQuery] = useState<string>("");
  const wines = useBlomqvistObjects(submittedQuery);

  return (
    <View style={styles.background}>
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <SearchField
              query={query}
              onChange={setQuery}
              disabled={wines.isLoading}
              placeholder={
                wines.isLoading
                  ? "Laster.."
                  : "Søk etter viner i tilslagslisten"
              }
            />
            <Button onPress={() => setSubmittedQuery(query)} title="Søk" />
          </View>

          {wines.data &&
            Object.entries(wines.data)
              .sort((a, b) => b[1].length - a[1].length)
              .map(([wineName, objects]) => (
                <BlomqvistItemAccordion
                  wineName={wineName}
                  objects={objects}
                  key={wineName}
                />
              ))}

          {wines.isLoading && (
            <View
              style={{
                minHeight: 100,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LoadingSpinner />
            </View>
          )}
        </View>
      </SafeAreaView>
    </View>
  );
}

interface BlomqvistItemAccordionProps {
  wineName: string;
  objects: BlomqvistObject[];
}

const renderItem = ({ item }) => <BlomqvistCard blomqvistItem={item} />;

const BlomqvistItemAccordion = ({
  wineName,
  objects,
}: BlomqvistItemAccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const items = [...objects].reverse().slice(0, itemsToShow);
  console.log(items);

  return (
    <Accordion
      onToggleListItem={setIsOpen}
      dropdownLabel={`${wineName} (${objects.length})`}
      accordionBodyStyle={{ width: "100%" }}
      accordionStyle={{ justifyContent: "flex-start" }}
    >
      {isOpen && (
        <>
          <BlomqvistPriceChart blomqvistItems={objects} />

          <FlatList<BlomqvistObject>
            data={items}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
          {itemsToShow < objects.length && (
            <Button
              title="Last flere"
              onPress={() => setItemsToShow(itemsToShow + 10)}
            />
          )}
        </>
      )}
    </Accordion>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4EFEA",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: 15,
    width: "100%",
    maxWidth: 700,
  },
  mainColumns: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  background: {
    alignItems: "center",
    flex: 1,
    backgroundColor: "#F4EFEA",
  },
  hitsAndSort: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
  },
  hits: {
    fontSize: 11,
    lineHeight: 12,
    textTransform: "uppercase",
  },
  sort: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  filterAccordion: {
    alignSelf: "stretch",
    backgroundColor: "#FFFFFF",
    marginTop: 10,
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  filterColumn: {
    backgroundColor: "#FFFFFF",
    marginTop: 89,
    marginRight: 15,
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "30%",
  },
});
