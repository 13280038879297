import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { Store, WineFilter } from "../types";
import { MAX_PRICE_FILTER_VALUE } from "../util/constants";
import { NameAndCount } from "../util/utils";
import { MultiPicker } from "./MultiPicker";
import { PriceRangeSlider } from "./PriceRangeSlider";
import { ScoreRangeSlider } from "./ScoreRangeSlider";

interface Props {
  wineFilter: WineFilter;
  stores: Store[];
  grapes: string[];
  locations: NameAndCount[];
  meals: string[];
  types: string[];
  onChange: (filter: WineFilter) => void;
  style?: StyleProp<ViewStyle>;
}

const removeIfPresentOrAdd = <T,>(array: T[], value: T): T[] => {
  if (array.includes(value)) {
    return array.filter((it) => it !== value);
  } else {
    return [...array, value];
  }
};

export const Filters = ({
  wineFilter,
  stores,
  grapes,
  locations,
  meals,
  types,
  onChange,
  style,
}: Props) => {
  return (
    <View style={style}>
      <MultiPicker<string>
        selectedItems={
          wineFilter.selectedStore ? [wineFilter.selectedStore.name] : []
        }
        searchable
        label={"Velg vinmonopol"}
        items={stores ? stores.map((store) => store.name) : []}
        onChange={(store) => {
          onChange({
            ...wineFilter,
            selectedStore:
              wineFilter.selectedStore?.name === store
                ? null
                : stores.find((it) => it.name === store),
          });
        }}
      />
      <View style={styles.horizontalSeparator} />
      <MultiPicker<string>
        selectedItems={wineFilter.selectedGrapes}
        label={"Velg drue"}
        items={grapes}
        onChange={(grape) => {
          onChange({
            ...wineFilter,
            selectedGrapes: removeIfPresentOrAdd(
              wineFilter.selectedGrapes,
              grape
            ),
          });
        }}
      />
      <View style={styles.horizontalSeparator} />
      <MultiPicker<string>
        selectedItems={wineFilter.selectedTypes}
        label={"Velg type"}
        items={types}
        onChange={(type) => {
          onChange({
            ...wineFilter,
            selectedTypes: removeIfPresentOrAdd(wineFilter.selectedTypes, type),
          });
        }}
      />
      <View style={styles.horizontalSeparator} />
      <MultiPicker<string>
        selectedItems={wineFilter.selectedLocations}
        label={"Land"}
        items={locations.map(({ name, count }) => `${name} (${count})`)}
        onChange={(country) => {
          console.log(country.split("(")[0]);
          onChange({
            ...wineFilter,
            selectedLocations: removeIfPresentOrAdd(
              wineFilter.selectedLocations,
              country.split("(")[0].trim()
            ),
          });
        }}
      />
      <View style={styles.horizontalSeparator} />
      <MultiPicker<string>
        selectedItems={wineFilter.selectedMeals}
        label={"Velg måltid"}
        items={meals}
        onChange={(meal) => {
          onChange({
            ...wineFilter,
            selectedMeals: removeIfPresentOrAdd(wineFilter.selectedMeals, meal),
          });
        }}
      />
      <View style={styles.horizontalSeparator} />
      {wineFilter.priceRange && (
        <PriceRangeSlider
          wrapperStyle={styles.priceSliderWrapper}
          priceRange={wineFilter.priceRange}
          setPriceRange={(priceChange) => {
            onChange({
              ...wineFilter,
              priceRange: priceChange,
            });
          }}
          minValue={0}
          maxValue={MAX_PRICE_FILTER_VALUE}
        />
      )}

      {wineFilter.scoreRange && (
        <ScoreRangeSlider
          wrapperStyle={styles.priceSliderWrapper}
          scoreRange={wineFilter.scoreRange}
          setScoreRange={(scoreRange) => {
            onChange({
              ...wineFilter,
              scoreRange,
            });
          }}
          minValue={50}
          maxValue={100}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  horizontalSeparator: {
    borderBottomColor: "#DED2C8",
    borderBottomWidth: 1,
  },
  priceSliderWrapper: {
    paddingBottom: 0,
  },
});
