import React, { useState } from "react";
import { View, Image, StyleSheet, Text, Linking } from "react-native";
import { BlomqvistObject, Wine } from "../types";
import { Badge } from "./Badge";
import { WineDetails } from "./WineDetails";
import { Accordion } from "./Accordion";
import { formatInteger } from "../util/formatter";

interface Props {
  blomqvistItem: BlomqvistObject;
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#FFF",
    width: "100%",
    marginVertical: 10,
    padding: 24,
    borderRadius: 4,
    shadowColor: "rgba(29, 6 , 2, .05)",
    shadowOffset: { height: 2, width: 2 },
    shadowOpacity: 1,
    shadowRadius: 4,
  },
  mainSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftPart: {
    width: "80%",
    paddingRight: 10,
  },
  rightPart: {
    width: "20%",
  },
  score: {
    fontSize: 32,
    lineHeight: 32,
  },
  type: {
    marginTop: 35,
    fontSize: 10,
    lineHeight: 12,
    textTransform: "uppercase",
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    marginBottom: 16,
    fontWeight: "400",
  },
  location: {
    fontSize: 14,
    color: "#2f2f2f",
    lineHeight: 20,
  },
  tinyLogo: {
    height: 250,
  },
  links: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  link: {
    marginRight: 10,
    color: "#2F2F2F",
    textDecorationLine: "underline",
  },
  summary: {
    fontSize: 12,
    fontStyle: "italic",
    marginTop: 20,
  },
  amount: {
    fontSize: 14,
    color: "#2f2f2f",
    lineHeight: 20,
  },
  badges: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  accordionBody: {
    padding: 15,
  },
  scoreContainer: {
    flexDirection: "row",
  },
  subScoreContainer: {
    paddingLeft: 12,
    justifyContent: "center",
  },
  subScoreContainerText: {
    color: "#6E6E6E",
    fontSize: 12,
  },
});

export const BlomqvistCard = ({ blomqvistItem }: Props) => {
  const salesPrice =
    blomqvistItem.salesPrice &&
    blomqvistItem.salesPrice / blomqvistItem.numberOfItems;
  const minimumPrice = blomqvistItem.minimumPrice / blomqvistItem.numberOfItems;
  const valuationPrice =
    blomqvistItem.valuationPrice / blomqvistItem.numberOfItems;

  return (
    <View style={styles.card}>
      <View style={styles.mainSection}>
        <View style={styles.leftPart}>
          <View style={styles.scoreContainer}>
            <Text style={styles.score}>
              {(salesPrice && `${formatInteger(salesPrice)} kr`) || "Usolgt"}
            </Text>
          </View>
          <View>
            <Text style={styles.amount}>
              Minstepris: {formatInteger(minimumPrice)} kr
            </Text>
          </View>
          <View>
            <Text style={styles.amount}>
              Verdivurdering: {formatInteger(valuationPrice)} kr
            </Text>
          </View>

          <Text style={styles.type}>
            {new Date(blomqvistItem.endDate).toLocaleDateString("no", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Text>
          <Text style={styles.title}>{blomqvistItem.wineName}</Text>
          <View>
            <Text style={styles.location}>{blomqvistItem.producer ?? ""}</Text>
            <Text style={styles.location}>{blomqvistItem.title ?? ""}</Text>
          </View>
          <View>
            <Text style={styles.summary}>
              Totalpris:{" "}
              {(blomqvistItem.salesPrice &&
                `${formatInteger(blomqvistItem.salesPrice)} kr`) ||
                "Usolgt"}
            </Text>
          </View>
        </View>
        <View style={styles.rightPart}>
          <Image
            resizeMode="contain"
            style={styles.tinyLogo}
            source={{
              uri: blomqvistItem.photos?.verylarge?.length
                ? blomqvistItem.photos?.verylarge[0]
                : undefined,
            }}
          />
        </View>
      </View>
    </View>
  );
};
