import React, { useMemo, useState } from "react";
import {
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
  StyleProp,
  TextStyle,
} from "react-native";
import {
  Picker as NativePicker,
  onOpen,
} from "react-native-actions-sheet-picker";
import { Picker as WebPicker } from "@react-native-picker/picker";

interface Props<T extends String> {
  id: string;
  selectedItem: T | "";
  items: T[];
  onChange: (value: T) => void;
  placeholder: string;
  webStyle?: StyleProp<TextStyle>;
}

export const Picker = <T extends string>({
  onChange,
  selectedItem,
  items,
  placeholder,
  id,
  webStyle,
}: Props<T>) => {
  const [query, setQuery] = useState("");

  const filteredData = useMemo(() => {
    if (items && items.length > 0) {
      return items.filter((item) =>
        item.toLocaleLowerCase("en").includes(query.toLocaleLowerCase("en"))
      );
    }
  }, [items, query]);

  if (!items) return null;

  if (Platform.OS !== "web") {
    const nativePickerData: { id: string; name: string }[] = filteredData.map(
      (data) => ({
        id: data,
        name: data,
      })
    );
    return (
      <>
        <TouchableOpacity
          onPress={() => {
            onOpen(id);
          }}
        >
          <Text>{selectedItem ?? placeholder}</Text>
        </TouchableOpacity>
        <NativePicker
          id={id}
          data={nativePickerData}
          inputValue={query}
          searchable={true}
          label={placeholder}
          setSelected={(data) => onChange(data.name as T)}
          onSearch={setQuery}
        />
      </>
    );
  }
  return (
    <WebPicker
      selectedValue={selectedItem ?? ""}
      onValueChange={onChange}
      style={[styles.webPicker, webStyle]}
    >
      <WebPicker.Item label={placeholder} enabled={false} value="" />
      {items.map((item) => (
        <WebPicker.Item label={item} value={item} key={item} />
      ))}
    </WebPicker>
  );
};

const styles = StyleSheet.create({
  webPicker: {
    paddingVertical: 10,
    backgroundColor: "transparent",
    fontWeight: "600",
    borderBottomColor: "#DED2C8",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
});
