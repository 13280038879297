import fuzzysort from "fuzzysort";
import { MinMaxRange, SortBy, Wine, WineFilter } from "../types";
import { MAX_PRICE_FILTER_VALUE } from "./constants";
import { parseNumber } from "./formatter";
import { trimSearchString } from "./utils";

export const sortWines = (wines: Wine[], sortBy: SortBy) => {
  if (sortBy === SortBy.Published) return wines.sort(byCreatedDate);
  if (sortBy === SortBy.Rating) return wines.sort(byRating);
  else return wines;
};

export const filterWines = (
  wines: Wine[],
  searchQuery: string,
  wineFilter: WineFilter
): Wine[] => {
  const filteredWines = wines
    .filter((it) => isOfTypes(it, wineFilter.selectedTypes))
    .filter((it) => goesWithMeals(it, wineFilter.selectedMeals))
    .filter((it) => containsGrapes(it, wineFilter.selectedGrapes))
    .filter((it) => containsLocations(it, wineFilter.selectedLocations))
    .filter((it) => isWithinPriceRange(it, wineFilter.priceRange))
    .filter((it) => isWithinScoreRange(it, wineFilter.scoreRange));
  return fuzzysearch(searchQuery, filteredWines);
};

const fuzzysearch = (query: string, wines: Wine[]) =>
  query?.length > 1
    ? fuzzysort
        .go(trimSearchString(query), wines, {
          keys: ["searchString", "fields.product_id.value"],
        })
        .map((it) => it.obj)
    : wines;

const byCreatedDate = (wineA: Wine, wineB: Wine) => {
  if (!wineA.createdDate && !wineB.createdDate) return 0;
  if (!wineA.createdDate) return 1;
  if (!wineB.createdDate) return -1;
  return wineB.createdDate?.getTime() - wineA.createdDate?.getTime();
};

const byRating = (wineA: Wine, wineB: Wine) => {
  if (!wineA.fields.score?.value && !wineB.fields.score?.value) return 0;
  if (!wineA.fields.score?.value) return 1;
  if (!wineB.fields.score?.value) return -1;
  return wineB.fields.score.value - wineA.fields.score.value;
};

const containsGrapes = (wine: Wine, selectedGrapes: string[]): boolean => {
  if (selectedGrapes.length === 0) return true;
  return selectedGrapes.some((grape) =>
    wine.fields.grape.value?.some((val) => val?.name === grape)
  );
};

export const containsLocations = (
  wine: Wine,
  selectedLocations: string[]
): boolean => {
  if (selectedLocations?.length === 0) return true;
  return selectedLocations.some(
    (location) =>
      wine.fields?.country?.value?.toLowerCase() === location?.toLowerCase()
  );
};

const isOfTypes = (wine: Wine, selectedTypes: string[]): boolean => {
  if (selectedTypes.length === 0) return true;
  return selectedTypes.some((type) => wine.fields.type.value === type);
};

const goesWithMeals = (wine: Wine, selectedMeals: string[]): boolean => {
  if (selectedMeals.length === 0) return true;
  return selectedMeals.some((meal) =>
    wine.fields.meal?.value?.some((val) => val === meal)
  );
};

const isWithinPriceRange = (wine: Wine, priceRange: MinMaxRange): boolean => {
  const winePrice = parseNumber(wine.fields?.price?.value || "-1");

  return (
    winePrice >= priceRange.min &&
    (priceRange.max === MAX_PRICE_FILTER_VALUE || winePrice <= priceRange.max)
  );
};

const isWithinScoreRange = (wine: Wine, scoreRange: MinMaxRange): boolean => {
  const winePoints = parseNumber(wine.fields?.score?.value || "-1");

  return winePoints >= scoreRange.min && winePoints <= scoreRange.max;
};
