import React, { useState } from "react";
import { View, Image, StyleSheet, Text, Linking } from "react-native";
import { Wine } from "../types";
import { Badge } from "./Badge";
import { WineDetails } from "./WineDetails";
import { Accordion } from "./Accordion";

interface Props {
  wine: Wine;
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#FFF",
    width: "100%",
    marginVertical: 10,
    padding: 24,
    borderRadius: 4,
    shadowColor: "rgba(29, 6 , 2, .05)",
    shadowOffset: { height: 2, width: 2 },
    shadowOpacity: 1,
    shadowRadius: 4,
  },
  mainSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftPart: {
    width: "80%",
    paddingRight: 10,
  },
  rightPart: {
    width: "20%",
  },
  score: {
    fontSize: 32,
    lineHeight: 32,
  },
  type: {
    marginTop: 35,
    fontSize: 10,
    lineHeight: 12,
    textTransform: "uppercase",
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    marginBottom: 16,
    fontWeight: "400",
  },
  location: {
    fontSize: 14,
    color: "#2f2f2f",
    lineHeight: 20,
  },
  tinyLogo: {
    height: 250,
  },
  links: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  link: {
    marginRight: 10,
    color: '#2F2F2F',
    textDecorationLine: 'underline',
  },
  summary: {
    fontSize: 12,
    fontStyle: "italic",
    marginTop: 20,
  },
  amount: {
    marginTop: 30,
    fontSize: 14,
    color: "#2f2f2f",
    lineHeight: 20,
  },
  badges: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  accordionBody: {
    padding: 15,
  },
  scoreContainer: {
    flexDirection: "row",
  },
  subScoreContainer: {
    paddingLeft: 12,
    justifyContent: "center",
  },
  subScoreContainerText: {
    color: "#6E6E6E",
    fontSize: 12,
  },
});

export const WineCard = ({ wine }: Props) => {
  const [shouldFetchDetails, setShouldFetchDetails] = useState(false);

  return (
    <View style={styles.card}>
      <View style={styles.mainSection}>
        <View style={styles.leftPart}>
          <View style={styles.scoreContainer}>
            <Text style={styles.score}>{wine.fields.score?.value ?? "-"}</Text>
            <View style={styles.subScoreContainer}>
              <Text style={styles.subScoreContainerText}>
                {wine.fields.tester?.value}
              </Text>
              <Text style={styles.subScoreContainerText}>
                {wine.createdDate?.toLocaleDateString("no", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Text>
            </View>
          </View>

          <Text style={styles.type}>{wine.fields.type?.value ?? ""}</Text>
          <Text style={styles.title}>
            {wine.title} {wine.fields.year?.value ?? ""}
          </Text>
          <View>
            <Text style={styles.location}>
              {wine.fields.producer?.value ?? ""}
            </Text>
            <Text style={styles.location}>
              {wine.fields.under_district?.value?.concat(", ") ?? ""}
              {wine.fields.district?.value?.concat(", ") ?? ""}
              {wine.fields.country?.value ?? ""}
            </Text>
            <Text style={styles.summary}>
              {wine.fields.drink_from?.value
                ? `Drikkes best fra ${wine.fields.drink_from?.value} til ${wine.fields.drink_to?.value}.`
                : ""}
            </Text>
            <View style={styles.links}>
              {wine.fields.product_id?.value &&
                <Text style={styles.link}
                  onPress={() => Linking.openURL(`https://vinmonopolet.no/p/${wine.fields.product_id.value}`)}>
                  vinmonopolet
                </Text>
              }
              {wine.dnUrl &&
                <Text style={styles.link}
                  onPress={() => Linking.openURL(wine.dnUrl)}>
                  dn/smak
                </Text>
              }
            </View>
          </View>
          <Text style={styles.amount}>
            {wine.fields.price?.value?.concat(" kr") ?? ""}
          </Text>
        </View>
        <View style={styles.rightPart}>
          <Image
            resizeMode="contain"
            style={styles.tinyLogo}
            source={{
              uri: `https://bilder.vinmonopolet.no/cache/515x515-0/${wine.fields.product_id?.value}-1.jpg`,
            }}
          />
        </View>
      </View>
      <View style={styles.badges}>
        {wine.fields.meal?.value &&
          wine.fields.meal.value.map((meal) => (
            <Badge key={meal}>{meal}</Badge>
          ))}
      </View>
      <Accordion
        onToggleListItem={() => setShouldFetchDetails(true)}
        accordionBodyStyle={styles.accordionBody}
      >
        {shouldFetchDetails && (
          <WineDetails id={wine.id} grapes={wine.fields.grape} />
        )}
      </Accordion>
    </View>
  );
};
