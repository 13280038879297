import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { BlomqvistObject } from "../types";
import { calculateSalesPricePerItem } from "../util/utils";

interface Props {
  blomqvistItems: BlomqvistObject[];
}

const getOptions = (blomqvistItems: BlomqvistObject[]) => ({
  chart: {
    panning: true,
  },
  rangeSelector: {
    enabled: true,
    selected: 1,
  },
  navigator: {
    enabled: true,
  },
  scrollbar: {
    enabled: true,
  },
  title: {
    text: "Prisutvikling",
    margin: 50,
  },
  subtitle: {
    text: null,
  },
  xAxis: {
    type: "datetime",
    crosshair: {
      color: "#cccccc",
      dashStyle: "Solid",
    },
  },
  yAxis: {
    opposite: true,
    offset: 50,
    labels: {
      align: "right",
      x: -3,
      y: 4,
    },
    title: {
      text: "Kroner",
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    footerFormat: "</tbody></table>",
    borderWidth: 0,
    valueDecimals: 2,
    formatter() {
      const dateFormat = "%A %e. %b %Y";
      let format =
        '<span style="font-size: 10px">%d</span><br>' + "%s <b>%n kr</b><br>";
      format = format.replace("%d", Highcharts.dateFormat(dateFormat, this.x));
      format = format.replace(
        "%n",
        Highcharts.numberFormat(this.points[0].y, 0)
      );
      format = format.replace("%s", "Salgspris:");

      return format;
    },
  },
  series: [
    {
      lineWidth: 1,
      name: "Salgspris (eksl salær)",
      data: blomqvistItems
        .filter((it) => it.salesPrice)
        .map((it) => [
          new Date(it.endDate).getTime(),
          calculateSalesPricePerItem(it),
        ]),
    },
  ],
});

export const BlomqvistPriceChart = ({ blomqvistItems }: Props) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={getOptions(blomqvistItems)}
  />
);
