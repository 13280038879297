import React, { useState } from "react";
import {
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Text,
  TextInput,
} from "react-native";
import { Accordion } from "./Accordion";
import fuzzysort from "fuzzysort";

interface Props<T extends String> {
  selectedItems: T[];
  searchable?: boolean;
  items: T[];
  label: string;
  onChange: (value: T) => void;
}

const Item = ({ item, onPress, isSelected }) => {
  const backgroundColor = isSelected ? "#FAF7F5" : "#FFFFFF";
  const color = isSelected ? "#610E3D" : "black";
  const fontWeight = isSelected ? "bold" : "normal";

  return (
    <TouchableOpacity onPress={onPress} style={{ backgroundColor }}>
      <Text style={[styles.title, { color }, { fontWeight }]}>
        {item.split("(")[0]}{" "}
        {item.split("(")[1] && (
          <Text style={[styles.count]}>({item.split("(")[1]}</Text>
        )}
      </Text>
    </TouchableOpacity>
  );
};

export const MultiPicker = <T extends string>({
  onChange,
  label,
  selectedItems,
  searchable,
  items,
}: Props<T>) => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const renderItem = ({ item }) => {
    return (
      <Item
        item={item}
        onPress={() => onChange(item)}
        isSelected={selectedItems && selectedItems.includes(item)}
      />
    );
  };

  return (
    <Accordion
      dropdownLabel={label}
      onToggleListItem={() => setShow(true)}
      accordionBodyStyle={styles.accordionBody}
    >
      {show && (
        <>
          {searchable && (
            <TextInput
              placeholder="søk..."
              style={{
                borderBottom: "solid #610E3D 1px",
                height: "40px",
                padding: "10px",
              }}
              onChangeText={(text) => setSearch(text)}
              value={search}
            />
          )}
          <FlatList
            data={(searchable && search) ? fuzzysort.go(search, items).map((it) => it.target) : items}
            renderItem={renderItem}
            keyExtractor={(item) => item}
            extraData={selectedItems}
          />
        </>
      )}
    </Accordion>
  );
};

const styles = StyleSheet.create({
  accordionBody: {
    padding: 0,
    alignSelf: "stretch",
    maxHeight: 400,
  },
  label: {
    color: "#000000",
    fontSize: 14,
    fontWeight: "bold",
  },
  title: {
    paddingVertical: 13,
    paddingHorizontal: 20,
    fontSize: 14,
    borderTopColor: "#f1ece7",
    borderTopWidth: 1,
  },
  count: {
    fontSize: 10,
    color: "#808080",
  },
});
