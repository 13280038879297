import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Animated,
  Easing,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

interface Props {
  dropdownLabel?: string;
  children: React.ReactNode;
  onToggleListItem?: (isNextStateOpen: boolean) => void;
  accordionBodyStyle?: StyleProp<ViewStyle>;
  accordionStyle?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;

  /**
   * Can be used if open/closed state of accordion should be handled by parent component
   */
  openState?: [boolean, Dispatch<SetStateAction<boolean>>];
}

const styles = StyleSheet.create({
  accordionWrapper: {
    width: "100%",
  },
  bodyBackground: {
    overflow: "hidden",
    alignItems: "flex-start",
  },
  accordion: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdownLabel: {
    fontWeight: "bold",
    paddingVertical: 15,
    paddingLeft: 15,
  },
});

export const Accordion = ({
  dropdownLabel,
  children,
  onToggleListItem,
  accordionBodyStyle,
  accordionStyle,
  wrapperStyle,
  openState,
}: Props) => {
  const [open, setOpen] = openState || useState<boolean>(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState<number>(50);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    onToggleListItem?.(!open);
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start();
    }
    setOpen(!open);
  };

  return (
    <View style={[styles.accordionWrapper, wrapperStyle]}>
      <TouchableWithoutFeedback onPress={() => toggleListItem()}>
        <View style={[styles.accordion, accordionStyle]}>
          {dropdownLabel && (
            <Text style={styles.dropdownLabel}>{dropdownLabel}</Text>
          )}
          <Animated.View
            style={{ transform: [{ rotateZ: arrowAngle }], marginRight: 8 }}
          >
            <MaterialIcons name="keyboard-arrow-down" size={24} color="black" />
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={accordionBodyStyle}
          onLayout={(event) =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }
        >
          {children}
        </View>
      </Animated.View>
    </View>
  );
};
